import React from "react"
import { Link } from "gatsby"

import Seo from "../components/Seo"
import PageWrapper from "../components/PageWrapper"

const HomePage = () => (
  <PageWrapper>
    <Seo title="Home" />

    <main className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8 justify-center items-center">
      <div className="flex-shrink-0 py-4 sm:py-8 text-center">
        {/* <h1 className="mt-2 mb-2 text-4xl font-extrabold  tracking-tight sm:text-5xl">
          Home
        </h1> */}
        <Link to="/brands" className="block underline leading-[45px] mt-5">
          <h1>Find the perfect fastener for your tool in 3 easy steps.</h1>
        </Link>
        <Link to="/fr/brands" className="block underline leading-[45px]">
          Trouver l’attache parfaite pour votre outil en 3 eětapes facile!
        </Link>
        <Link
          to="/terms"
          className="block font-normal text-[1.2rem]  leading-[30px] mx-[30px] my-[60px]"
        >
          About & Terms of Use (v1.2)
        </Link>
      </div>
    </main>
  </PageWrapper>
)

export default HomePage
